import { NewspaperLastNews } from '@molecules/frontoffice/NewspaperLastNews';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSideProps } from 'next';
import { Grid, Stack, Title, Text, useMantineTheme, Flex } from '@mantine/core';
import { NewsCard } from '@molecules/frontoffice/NewsCard';
import {
  fetchCategories,
  useFetchCategories,
} from '@hooks/queries/useFetchCategories';
import Link from 'next/link';
import { InView } from 'react-intersection-observer';
import {
  fetchInfiniteNews,
  useFetchInfiniteNews,
} from '@hooks/queries/useFetchInfiniteNews';
import { getStringDayMonthAndYear } from '@utils/dates';
import { useTranslation } from 'next-i18next';
import {
  fetchNewsByCategory,
  useFetchNewsByCategory,
} from '@hooks/queries/useFetchCategoryNews';
import { QUERY_KEY } from '@constants/queries';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import {
  fetchLiveBlogs,
  useFetchLiveBlogs,
} from '@hooks/queries/useFetchLiveBlogs';
import { FETCH_LIMIT_LIVE_BLOGS, SINGLE_PAGE } from '@constants/pagination';
import { LoadingStateComponent } from '@molecules/frontoffice/LoadingStateComponent';
import { EmptyStateComponent } from '@molecules/frontoffice/EmptyStateComponent';
import {
  fetchPublicity,
  useFetchPublicity,
} from '@hooks/queries/useFetchPublicity';
import {
  fetchPublicConfiguration,
  useFetchPublicConfiguration,
} from '@hooks/queries/useFetchPublicConfiguration';
import { getWhiteSpacePublicity } from '@helpers/configurations';
import { TitleUnderline } from '@atoms/TitleUnderline';
import { RandomPublicityComponent } from '@organisms/RandomPublicityComponent';
import { SEO } from '@organisms/SEO';
import { useMemo } from 'react';
import { PublicNews } from '@utils/typesPages/types';
import { fetchSEO, useFetchSEO } from '@hooks/queries/useFetchSEO';
import { DEFAULT_LOCALE } from '@constants/locales';
import {
  fetchNewsHighlighted,
  useFetchNewsHighlighted,
} from '@hooks/queries/useFetchNewsHighlighted';

const doesNotExistInHighlighted = (
  newsItem: PublicNews,
  highlightedNewsSet: Set<string>
) => {
  return !highlightedNewsSet.has(newsItem.id);
};

export const getServerSideProps: GetServerSideProps = async ({ ...ctx }) => {
  const queryClient = new QueryClient();

  const paramsLiveBlog = {
    limit: FETCH_LIMIT_LIVE_BLOGS,
    page: SINGLE_PAGE,
  };

  const paramsPublicity = {
    fetchAll: true,
  };

  const categoriesPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_CATEGORIES, { fetchAll: true }],
    queryFn: async () => {
      return await fetchCategories({ fetchAll: true });
    },
  });

  const liveBlogsPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_LIVE_BLOGS, paramsLiveBlog],
    queryFn: async () => {
      return await fetchLiveBlogs(paramsLiveBlog);
    },
  });

  const newsPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_BY_CATEGORY],
    queryFn: async () => {
      return await fetchNewsByCategory();
    },
  });

  const configurationsPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_PUBLIC_CONFIGURATION],
    queryFn: async () => {
      return await fetchPublicConfiguration();
    },
  });

  const publicityPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_PUBLICITY, paramsPublicity],
    queryFn: async () => {
      return await fetchPublicity(paramsPublicity);
    },
  });

  const seoPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_SEO],
    queryFn: async () => {
      return await fetchSEO();
    },
  });

  const newsHighlightedPromise = queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_HIGHLIGHTED],
    queryFn: async () => {
      return await fetchNewsHighlighted();
    },
  });

  const newsPublicPromise = queryClient.prefetchInfiniteQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_PUBLIC],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => fetchInfiniteNews({ pageParam }),
  });

  await Promise.all([
    categoriesPromise,
    liveBlogsPromise,
    newsPromise,
    configurationsPromise,
    publicityPromise,
    seoPromise,
    newsHighlightedPromise,
    newsPublicPromise,
  ]);

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, [
        'common',
        'news',
        'liveBlogDetails',
      ])),
      dehydratedState: dehydrate(queryClient),
    },
  };
};

const Home = () => {
  const theme = useMantineTheme();

  const { data: dataSEO, isLoading: isLoadingSEO } = useFetchSEO();

  const SEOLanding = dataSEO?.value.find((page) => page.name === 'landing');

  const { t } = useTranslation('liveBlogDetails');

  const { data: configurations } = useFetchPublicConfiguration();

  const { data: categories } = useFetchCategories({
    fetchAll: true,
  });

  const { data: publicity } = useFetchPublicity({
    fetchAll: true,
  });

  const {
    data: infiniteNews,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetched,
  } = useFetchInfiniteNews();

  const {
    data: dataHighlighted,
    isFetched: isFetchedHighlighted,
    isLoading: isLoadingHighlighted,
  } = useFetchNewsHighlighted();

  const { data: liveBlogs } = useFetchLiveBlogs({
    limit: FETCH_LIMIT_LIVE_BLOGS,
    page: SINGLE_PAGE,
  });

  const { data: newsByCategory } = useFetchNewsByCategory(
    categories
      ? categories?.data.map((category) => {
          return category.slug;
        })
      : []
  );

  const groupNewsByDate = (newsItems: PublicNews[]) => {
    return newsItems.reduce(
      (acc, newsItem) => {
        const publishDate = newsItem.publish_at.split('T')[0];
        if (!acc[publishDate]) {
          acc[publishDate] = [];
        }
        acc[publishDate].push(newsItem);
        return acc;
      },
      {} as Record<string, PublicNews[]>
    );
  };

  const groupedNews = useMemo(() => {
    const grouped = infiniteNews?.pages.reduce(
      (acc, page) => {
        const newsByDate = groupNewsByDate(page.data);
        for (const date in newsByDate) {
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date] = acc[date].concat(newsByDate[date]);
        }
        return acc;
      },
      {} as Record<string, PublicNews[]>
    );

    if (grouped) {
      return Object.entries(grouped)
        .map(([date, newsItems]) => ({
          date,
          newsItems,
        }))
        .sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
    }
  }, [infiniteNews]);

  const hasSettled =
    isFetchedHighlighted &&
    !isLoadingHighlighted &&
    isFetched &&
    !isLoading &&
    !isLoadingSEO;

  if (!hasSettled) {
    return <LoadingStateComponent />;
  }

  if (
    (hasSettled && !infiniteNews) ||
    (hasSettled && infiniteNews?.pages[0].meta.total_pages === 0)
  ) {
    return <EmptyStateComponent />;
  }

  const isLiveBlogEmpty = liveBlogs?.data.length === 0;

  const highlightedNewsIds = new Set(
    dataHighlighted?.data.map((news) => news.id)
  );

  const filteredInfiniteNews =
    infiniteNews?.pages[0].data.filter((news) =>
      doesNotExistInHighlighted(news, highlightedNewsIds)
    ) ?? [];

  const combinedNews = [
    ...(dataHighlighted?.data || []),
    ...filteredInfiniteNews,
  ];

  const secondSectionNews = combinedNews.slice(1, 3);
  const thirdSectionNews = combinedNews.slice(3, 5);
  const fourthSectionNews = combinedNews.slice(
    isLiveBlogEmpty ? 5 : 3,
    isLiveBlogEmpty ? 7 : 5
  );

  return (
    <>
      <SEO
        title={SEOLanding?.title}
        description={SEOLanding?.description}
        keywords={SEOLanding?.keywords}
      />
      <Flex direction="column" mb={80} columnGap={0}>
        <Grid columns={4} m={0} gutter="sm" gutterMd={40}>
          <Grid.Col sm={4} lg={2} order={1}>
            <NewsCard
              news={dataHighlighted?.data[0] ?? infiniteNews?.pages[0].data[0]}
              hasBadge
              titleOrder={1}
              titleVariant="h1"
            />
          </Grid.Col>
          <Grid.Col md={4} lg={1} order={2}>
            <Stack spacing="md">
              {secondSectionNews?.map((news, index) => (
                <Flex key={index} mt={{ base: 30, lg: 0 }}>
                  <NewsCard
                    news={news}
                    hasBadge
                    titleOrder={2}
                    titleVariant="h3Dark"
                    hasDescription={false}
                  />
                </Flex>
              ))}
            </Stack>
          </Grid.Col>
          {/** LIVE BLOG SECTION */}
          <Grid.Col mt={{ base: 24, lg: 0 }} md={4} lg={1} order={2}>
            {isLiveBlogEmpty ? (
              <Stack spacing="md">
                {thirdSectionNews?.map((news, index) => (
                  <Flex key={index} mt={{ base: 30, lg: 0 }} w="100%">
                    <NewsCard
                      news={news}
                      hasBadge
                      titleOrder={3}
                      titleVariant="h3Dark"
                      hasDescription={false}
                    />
                  </Flex>
                ))}
              </Stack>
            ) : (
              <Stack spacing="xs">
                {liveBlogs?.data.map((liveBlog, index) => (
                  <Grid.Col
                    style={{ padding: 0 }}
                    key={index}
                    sm={12}
                    md={4}
                    mt={index === 0 ? 0 : 'md'}
                  >
                    <Link
                      href={`em-direto/${liveBlog?.slug}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Stack spacing={4}>
                        <Text variant="categoryText">
                          {liveBlog.category.name.toLocaleUpperCase()}
                        </Text>
                        <Title mt={4} order={3} variant="h4Dark" lineClamp={3}>
                          <span
                            style={{
                              color: theme.colors.primary[0],
                              marginRight: 3,
                            }}
                          >
                            {t('live')}
                          </span>
                          {liveBlog.title}
                        </Title>
                        <Text variant="body" mt={4} component="p" lineClamp={4}>
                          {liveBlog.description}
                        </Text>
                      </Stack>
                    </Link>
                  </Grid.Col>
                ))}
              </Stack>
            )}
          </Grid.Col>
          {fourthSectionNews?.map((news, index) => (
            <Grid.Col key={index} md={4} lg={1} mt={30} order={5}>
              <NewsCard
                key={index}
                news={news}
                hasBadge
                titleOrder={3}
                titleVariant="h3Dark"
              />
            </Grid.Col>
          ))}

          <Grid.Col md={4} lg={1} mt={30} order={5}>
            <NewspaperLastNews
              news={infiniteNews?.pages[0].data.slice(0, 5)}
              isLoading={isLoading}
            />
          </Grid.Col>
          <Grid.Col md={2} lg={1} mt={{ base: 20, lg: 30 }} order={5}>
            <RandomPublicityComponent
              type="SIDEBAR"
              index={0}
              publicities={publicity?.data ?? []}
            />
          </Grid.Col>
        </Grid>
        {/** CATEGORY SECTION */}
        {newsByCategory?.map(
          (categoryNews, index) =>
            categoryNews?.data.length > 0 && (
              <Flex direction="column" key={index} mt={{ base: 30, lg: 40 }}>
                <Link
                  href={`/noticias/${categoryNews.data[0].category.slug}`}
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    zIndex: 20,
                  }}
                >
                  <Title variant="h2Orange" order={2}>
                    {categoryNews.data[0].category.name.toLocaleUpperCase()}
                  </Title>
                  <TitleUnderline />
                </Link>
                <Grid gutter="sm" gutterLg={40} m={0}>
                  {categoryNews.data?.map((categoryNews, index) => (
                    <Grid.Col mt={20} key={index} sm={12} md={3} mah={600}>
                      <NewsCard
                        news={categoryNews}
                        hasBadge
                        titleOrder={3}
                        titleVariant="h3Dark"
                      />
                    </Grid.Col>
                  ))}
                  {index % getWhiteSpacePublicity(configurations) === 0 && (
                    <Grid.Col sm={12} md={3} mah={400} mt={20}>
                      <RandomPublicityComponent
                        type="SIDEBAR"
                        publicities={publicity?.data ?? []}
                        index={
                          publicity
                            ? // Returns index depending on configuration of WhiteSpacePublicity
                              Math.ceil(
                                (index +
                                  getWhiteSpacePublicity(configurations)) /
                                  getWhiteSpacePublicity(configurations)
                              )
                            : 0
                        }
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Flex>
            )
        )}
        {/** INFINITE NEWS SECTION */}
        {groupedNews?.map(({ date, newsItems }, indexGroup) => (
          <Flex direction="column" key={indexGroup} mt={{ base: 30, lg: 40 }}>
            <Flex direction="column">
              <Title variant="h2Orange" order={2}>
                {getStringDayMonthAndYear(date).toLocaleUpperCase()}
              </Title>
              <TitleUnderline />
            </Flex>
            <Grid gutter="sm" gutterLg={40}>
              {newsItems.map((singleNews, index) => {
                return (
                  <Grid.Col mt={20} key={index} sm={12} md={3} mah={600}>
                    {newsItems.length - 1 === index ? (
                      <InView
                        as="div"
                        style={{ padding: 0, margin: 0 }}
                        onChange={(inView) => {
                          if (
                            hasNextPage &&
                            inView &&
                            indexGroup === groupedNews.length - 1
                          ) {
                            fetchNextPage();
                          }
                        }}
                        key={index}
                        threshold={0.8}
                      >
                        <NewsCard
                          news={singleNews}
                          hasBadge
                          titleOrder={3}
                          titleVariant="h3Dark"
                        />
                      </InView>
                    ) : (
                      <NewsCard
                        news={singleNews}
                        hasBadge
                        titleOrder={3}
                        titleVariant="h3Dark"
                      />
                    )}
                  </Grid.Col>
                );
              })}
              <Grid.Col key={indexGroup} mt={20} sm={12} md={3}>
                {indexGroup % getWhiteSpacePublicity(configurations) === 0 && (
                  <RandomPublicityComponent
                    type="SIDEBAR"
                    publicities={publicity?.data ?? []}
                    index={Math.ceil(
                      indexGroup / getWhiteSpacePublicity(configurations)
                    )}
                  />
                )}
              </Grid.Col>
            </Grid>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Home;
