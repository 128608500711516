import { Button, Center, Divider, Flex, Loader, Title } from '@mantine/core';
import { FC } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { NewspaperLastNewsProps } from './types';
import { InlineNews } from '@molecules/frontoffice/InlineNews';

const LAST_NEWS_LIMIT = 7;

export const NewspaperLastNews: FC<NewspaperLastNewsProps> = ({
  news,
  isLoading,
}) => {
  const { t } = useTranslation('common');

  const areNewsAvailable = !isLoading && Array.isArray(news) && news.length > 0;

  return (
    <Flex direction="column" gap={4}>
      <Link href="/ultimas" style={{ textDecoration: 'none' }}>
        <Title variant="h4Dark" order={4} mb={4}>
          {t('common.lastOnes')}
        </Title>
      </Link>
      {areNewsAvailable ? (
        news?.map(
          (singleNews, index) =>
            index < LAST_NEWS_LIMIT && (
              <InlineNews key={singleNews.slug} news={singleNews} />
            )
        )
      ) : (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}
      <Link
        href="/ultimas"
        style={{ textDecoration: 'none', marginTop: '0.625rem' }}
      >
        <Divider />
        <Center>
          <Button variant="subtle" rightIcon={<ArrowRightIcon />}>
            {t('common.seeMore')}
          </Button>
        </Center>
      </Link>
    </Flex>
  );
};
