import { QUERY_KEY } from '@constants/queries';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import { PublicNews, ApiListResponse } from '@utils/typesPages/types';
import { FETCH_LIMIT_CATEGORY_NEWS } from '@constants/pagination';

export const fetchInfiniteNews = async ({
  pageParam,
}: {
  pageParam: number;
}) => {
  const params: { limit: number; page: number; category?: string } = {
    limit: FETCH_LIMIT_CATEGORY_NEWS,
    page: pageParam,
  };

  const { data } = await GET<ApiListResponse<PublicNews[]>>('/api/news', {
    params: params,
  });

  return data;
};

export const useFetchInfiniteNews = () => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_PUBLIC],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => fetchInfiniteNews({ pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.total_pages === lastPage.meta.current_page
        ? undefined
        : lastPage.meta.current_page + 1;
    },
    refetchInterval: 300000,
    gcTime: 300000,
    refetchOnWindowFocus: true,
  });
};
