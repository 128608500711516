import { FETCH_LIMIT_CATEGORY_NEWS, SINGLE_PAGE } from '@constants/pagination';
import { QUERY_KEY } from '@constants/queries';

import { QueryClient, useQuery } from '@tanstack/react-query';

import { fetchNews } from './useFetchNews';

import { fetchCategories } from './useFetchCategories';
import { CACHE_TIME_TANSTACK } from '@constants/configurations';

export const fetchNewsByCategory = async () => {
  const newsQueries = [];

  const queryClient = new QueryClient();

  const categories = await fetchCategories({ fetchAll: true });

  for (const category of categories.data) {
    const categoryId = category.id;

    const newsQueryKey = [
      QUERY_KEY.FETCH_NEWS,
      {
        page: SINGLE_PAGE,
        limit: FETCH_LIMIT_CATEGORY_NEWS,
        category: categoryId,
      },
    ];

    const newsQuery = queryClient.getQueryData(newsQueryKey);

    if (!newsQuery) {
      newsQueries.push(
        queryClient.fetchQuery({
          queryKey: newsQueryKey,
          queryFn: () =>
            fetchNews({
              page: SINGLE_PAGE,
              limit: FETCH_LIMIT_CATEGORY_NEWS,
              category: categoryId,
            }),
        })
      );
    }
  }

  const response = await Promise.all(newsQueries);

  return response;
};

export const useFetchNewsByCategory = (categories: string[]) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_BY_CATEGORY],
    queryFn: () => fetchNewsByCategory(),
    enabled: categories.length > 0,
    refetchInterval: CACHE_TIME_TANSTACK,
    gcTime: CACHE_TIME_TANSTACK,
    refetchOnWindowFocus: true,
  });
};
