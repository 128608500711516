import { QUERY_KEY } from '@constants/queries';

import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import {
  PublicNewsFilters,
  PublicNews,
  ApiListResponse,
} from '@utils/typesPages/types';

export const fetchNews = async (params: PublicNewsFilters) => {
  const { data } = await GET<ApiListResponse<PublicNews[]>>('/api/news', {
    params: params,
  });

  return data;
};

export const useFetchNews = (params: PublicNewsFilters) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS, params],
    queryFn: () => fetchNews(params),
    refetchInterval: 300000,
    gcTime: 300000,
  });
};
