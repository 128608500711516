import { QUERY_KEY } from '@constants/queries';

import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import {
  ApiListResponse,
  LiveBlog,
  PublicLiveBlogFilters,
} from '@utils/typesPages/types';

export const fetchLiveBlogs = async (params: PublicLiveBlogFilters) => {
  const { data } = await GET<ApiListResponse<LiveBlog[]>>('/api/live-blogs', {
    params,
  });

  return data;
};

export const useFetchLiveBlogs = (params: PublicLiveBlogFilters) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_LIVE_BLOGS, params],
    queryFn: () => fetchLiveBlogs(params),
    refetchInterval: 300000,
    gcTime: 300000,
    refetchOnWindowFocus: true,
  });
};
