export const FETCH_LIMIT = 7;
export const FETCH_LIMIT_NEWS = 16;
export const FETCH_LIMIT_LIVE_BLOGS = 4;
export const SECTION_CATEGORIES_FETCH_LIMIT = 6;
export const SINGLE_PAGE = 1;
export const FETCH_MAX_LIMIT = 100;
export const FETCH_LIMIT_INFINITE_NEWS = 7;
export const FETCH_LIMIT_CATEGORY_NEWS = 7;
export const FETCH_LIMIT_PODCASTS = 8;
export const FETCH_LIMIT_SEARCH = 10;
export const FETCH_LIMIT_SEARCH_AUTHORS = 6;
export const FETCH_LIMIT_RECOMENDATIONS = 6;
