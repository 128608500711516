import { QUERY_KEY } from '@constants/queries';

import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import { ApiListResponse, News, NewsFilters } from '@utils/typesPages/types';

export const fetchNewsHighlighted = async () => {
  const params: NewsFilters = {
    limit: 7,
    page: 0,
    is_highlighted: true,
  };

  const { data } = await GET<ApiListResponse<News[]>>('/api/news', {
    params: params,
  });

  return data;
};

export const useFetchNewsHighlighted = () => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_HIGHLIGHTED],
    queryFn: fetchNewsHighlighted,
    refetchInterval: 300000,
    gcTime: 300000,
    refetchOnWindowFocus: true,
  });
};
